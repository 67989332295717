import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import CenteredText from "../components/sections/_centered-text";
import LogosSection from "../components/sections/_logos-section";
import {componentMap, DataProvider} from "../contentful/data-provider";
import ogimage from "../images/ogimages/media-kit.png";

export default function MediaKitPage({data}) {
    const dataProvider = new DataProvider(data);
    return (
        <Layout>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - Media Kit',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - Media Kit',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - Media Kit</title>
            </Helmet>
            <div className={'brand-page'}>
                <>
                    { dataProvider.getCurrentPageSections().map((section, index) => {
                        return (
                            <>
                                { dataProvider.getElementById(section.id) &&
                                    React.createElement(
                                        componentMap[section.type],
                                        {content: dataProvider.getElementById(section.id)}
                                    )}
                            </>
                        );
                    }) }
                </>

                <section className="pt-0 mt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 col-lg-5">
                                <h2>Highlight Reel</h2>
                                <p>Dean Tribble, CEO</p>
                            </div>
                            <div className="mt-4 col-12">
                                <iframe width="100%" height="500" src="https://www.youtube.com/embed/xzI_TONRT08"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                />
                            </div>
                        </div>
                        <LogosSection />
                    </div>
                </section>

                <CenteredText
                    content={{
                        title:'Download the Agoric media Kit',
                        ctaText: 'Download',
                        ctaUrl: 'https://assets.ctfassets.net/xm0kp9xt5r54/3RXfs1ls6idTM4Qj2gLlvB/807169b30398ba851b5779e948bfbcab/Agoric-Media-Kit.zip'
                    }}
                />
            </div>
        </Layout>
    );
}

export const query = graphql`
    query {
        contentfulPage(id: {eq: "bcff7ac6-3430-5d46-a5ee-7cc40ec1498f"}) {
            ... CurrentPageDetails
        }
        allContentfulEntry {
            edges {
                node {
                    ... AllEntryDetails
                }
            }
        }
    }
`;
