import React from "react";
import brandFullPngColor from "../../img/BRAND/AGORIC LOGO/FULL/PNG/Agoric-logo-color.png";
import brandFullPngBlack from "../../img/BRAND/AGORIC LOGO/FULL/PNG/Agoric-logo-black.png";
import brandFullPngWhite from "../../img/BRAND/AGORIC LOGO/FULL/PNG/Agoric-logo-white.png";
import brandFullSvgColor from "../../img/BRAND/AGORIC LOGO/FULL/SVG/Agoric-logo-color.svg";
import brandFullSvgBlack from "../../img/BRAND/AGORIC LOGO/FULL/SVG/Agoric-logo-bw.svg";
import brandFullSvgWhite from "../../img/BRAND/AGORIC LOGO/FULL/SVG/Agoric-logo-white.svg";
import brandSymbolSvgWhite from "../../img/BRAND/AGORIC LOGO/SYMBOL/SVG/Agoric-logo-white.svg";
import brandSymbolSvgBlack from "../../img/BRAND/AGORIC LOGO/SYMBOL/SVG/Agoric-logo-bw.svg";
import brandSymbolSvgColor from "../../img/BRAND/AGORIC LOGO/SYMBOL/SVG/Agoric-logo-color.svg";
import brandSymbolPngWhite from "../../img/BRAND/AGORIC LOGO/SYMBOL/PNG/Agoric-logo-white.png";
import brandSymbolPngBlack from "../../img/BRAND/AGORIC LOGO/SYMBOL/PNG/Agoric-logo-black.png";
import brandSymbolPngColor from "../../img/BRAND/AGORIC LOGO/SYMBOL/PNG/Agoric-logo-color.png";
import bldColorSvg from "../../img/BRAND/BLD LOGO/bld-logo.svg";
import bldColorPng from "../../img/BRAND/BLD LOGO/bld-logo.png";
import bldBlackSvg from "../../img/BRAND/BLD LOGO/bld-logo-empty.svg";
import bldBlackPng from "../../img/BRAND/BLD LOGO/bld-logo-empty.png";
import bldWhiteSvg from "../../img/BRAND/BLD LOGO/bld-logo-white.svg";
import bldWhitePng from "../../img/BRAND/BLD LOGO/bld-logo-white.png";
import { graphql, useStaticQuery } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const LogosSection = ({ content }) => {
	const logoUsageQuery = useStaticQuery(graphql`
		query {
			contentfulLabel(key: { eq: "logo-usage" }) {
				key
				value {
					raw
				}
				isEnabled
			}
		}
	`);
	const logoUsage = logoUsageQuery.contentfulLabel;

	return (
		<>
			<div className='row'>
				<div className='logo-usage col-12 text-col'>{renderRichText(logoUsage.value)}</div>
                <div class="col-12"><h2>Our logos</h2></div>
				<div className='row primary-logos'>
					<div className='py-3 col-12 col-sm-6 col-lg-4'>
						<div className='media full-width'>
							<img src={brandFullPngColor} alt='Agoric-logo-color' />
						</div>
						<div className='download'>
							Download:{" "}
							<a href={brandFullPngColor} download='Agoric-logo-color' className='link'>
								.png
							</a>
							<a href={brandFullSvgColor} download='Agoric-logo-color' className='link'>
								.svg
							</a>
							<div className='clear' />
						</div>
					</div>
					<div className='py-3 col-12 col-sm-6 col-lg-4'>
						<div className='media full-width'>
							<img src={brandFullPngBlack} alt='Agoric-logo-black' />
						</div>
						<div className='download'>
							Download:{" "}
							<a href={brandFullPngBlack} download='Agoric-logo-black' className='link'>
								.png
							</a>
							<a href={brandFullSvgBlack} download='Agoric-logo-black' className='link'>
								.svg
							</a>
							<div className='clear' />
						</div>
					</div>
					<div className='py-3 col-12 col-sm-6 col-lg-4'>
						<div className='media full-width inverse'>
							<img src={brandFullSvgWhite} alt='Agoric-logo-white' />
						</div>
						<div className='download'>
							Download:
							<a href={brandFullPngWhite} download='Agoric-logo-white' className='link'>
								.png
							</a>
							<a href={brandFullSvgWhite} download='Agoric-logo-white' className='link'>
								.svg
							</a>
							<div className='clear' />
						</div>
					</div>
				</div>
			</div>
			<div className='row alt-logos'>
				<div className='col-12'>
					<h2>Alternative logos</h2>
				</div>

				<div className='py-3 col-12 col-sm-6 col-lg-4'>
					<div className='media'>
						<img src={brandSymbolPngColor} alt='Agoric-logo-color' />
					</div>
					<div className='download'>
						Download:
						<a href={brandSymbolPngColor} download='Agoric-logo-color' className='link'>
							.png
						</a>
						<a href={brandSymbolSvgColor} download='Agoric-logo-color' className='link'>
							.svg
						</a>
						<div className='clear' />
					</div>
				</div>
				<div className='py-3 col-12 col-sm-6 col-lg-4'>
					<div className='media'>
						<img src={brandSymbolPngBlack} alt='Agoric-logo-black' />
					</div>
					<div className='download'>
						Download:{" "}
						<a href={brandSymbolPngBlack} download='Agoric-logo-black' className='link'>
							.png
						</a>
						<a href={brandSymbolSvgBlack} download='Agoric-logo-black' className='link'>
							.svg
						</a>
						<div className='clear' />
					</div>
				</div>
				<div className='py-3 col-12 col-sm-6 col-lg-4'>
					<div className='media inverse'>
						<img src={brandSymbolPngWhite} alt='Agoric-logo-white' />
					</div>
					<div className='download'>
						Download:{" "}
						<a href={brandSymbolPngWhite} download='Agoric-logo-white' className='link'>
							.png
						</a>
						<a href={brandSymbolSvgWhite} download='Agoric-logo-white' className='link'>
							.svg
						</a>
						<div className='clear' />
					</div>
				</div>
			</div>
			<div className='row bld-logos'>
				<div className='col-12'>
					<h2>BLD logo</h2>
				</div>

				<div className='py-3 col-12 col-sm-6 col-lg-4'>
					<div className='media'>
						<img src={bldColorPng} alt='BLD-logo-color' />
					</div>
					<div className='download'>
						Download:{" "}
						<a href={bldColorPng} download='bld-logo-color' className='link'>
							.png
						</a>
						<a href={bldColorSvg} download='bld-logo-color' className='link'>
							.svg
						</a>
						<div className='clear' />
					</div>
				</div>
				<div className='py-3 col-12 col-sm-6 col-lg-4'>
					<div className='media'>
						<img src={bldBlackPng} alt='BLD-logo-black' />
					</div>
					<div className='download'>
						Download:{" "}
						<a href={bldBlackPng} download='bld-logo-black' className='link'>
							.png
						</a>
						<a href={bldBlackSvg} download='bld-logo-black' className='link'>
							.svg
						</a>
						<div className='clear' />
					</div>
				</div>
				<div className='py-3 col-12 col-sm-6 col-lg-4'>
					<div className='media inverse'>
						<img src={bldWhitePng} alt='BLD-logo-white' />
					</div>
					<div className='download'>
						Download:
						<a href={bldWhitePng} download='bld-logo-white' className='link'>
							.png
						</a>
						<a href={bldWhiteSvg} download='bld-logo-white' className='link'>
							.svg
						</a>
						<div className='clear' />
					</div>
				</div>
			</div>
			<div className='row colors'>
				<div className='col-12'>
					<h2 className='mb-4'>Colors</h2>
				</div>
				<div className='col-12'>
					<h5>Main colors</h5>
				</div>
				<div className='py-3 col-12 col-sm-6 col-lg-4'>
					<div className='media' style={{ backgroundColor: "#BB2D40" }}>
						<div className='text'>
							<div className='title'>Brick red</div>
							<div className='subtitle'>
								<span>HEX : #BB2D40</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<span>RGB : 187, 45, 64</span>
							</div>
						</div>
					</div>
				</div>
				<div className='py-3 col-12 col-sm-6 col-lg-4'>
					<div className='media' style={{ backgroundColor: "#000000" }}>
						<div className='text'>
							<div className='title'>Black</div>
							<div className='subtitle'>
								<span>HEX : #000000</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<span>RGB : 0, 0, 0</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LogosSection;
